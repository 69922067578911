import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { theme } from './theme';

// Define interfaces
interface GuessResult {
  letter: string;
  state: 'correct' | 'wrong-position' | 'incorrect';
}

interface KeyboardKey {
  letter: string;
  state: 'unused' | 'correct' | 'wrong-position' | 'incorrect';
}

// Add this constant after the interfaces
const KEYBOARD_LAYOUT = [
  ['й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з', 'х', 'ъ'],
  ['ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж', 'э'],
  ['BACK', 'я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'б', 'ю', 'ё', 'ENTER']
];

// Add this interface after the existing interfaces
interface CustomWordModalProps {
  wordLength: number;
  onSubmit: (word: string) => void;
  onClose: () => void;
}

// Add these utility functions at the top of the file, after the imports
const encodeWord = (word: string): string => {
  // Convert to UTF-8 bytes
  const encoder = new TextEncoder();
  const bytes = encoder.encode(word);
  
  // Shift each byte
  const shifted = Array.from(bytes).map(b => (b + 3) % 256);
  
  // Convert to base64 and make URL safe
  const base64 = btoa(String.fromCharCode(...shifted));
  return base64
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

const decodeWord = (encoded: string): string => {
  try {
    // Restore base64 padding
    const padding = encoded.length % 4;
    const withPadding = padding ? 
      encoded + '='.repeat(4 - padding) : 
      encoded;
    
    // Convert from URL-safe format
    const base64 = withPadding
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    
    // Decode base64 and unshift bytes
    const bytes = new Uint8Array(
      atob(base64)
        .split('')
        .map(c => (c.charCodeAt(0) - 3 + 256) % 256)
    );
    
    // Convert back to string
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
  } catch {
    return '';
  }
};

// Add this component before the App component
const CustomWordModal: React.FC<CustomWordModalProps> = ({ wordLength, onSubmit, onClose }) => {
  const [customWord, setCustomWord] = useState('');
  const [error, setError] = useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSubmit = () => {
    const word = customWord.toLowerCase();
    if (word.length !== wordLength) {
      setError(`Слово должно быть длиной ${wordLength} букв`);
      return;
    }
    if (!/^[а-яё]+$/.test(word)) {
      setError('Используйте только русские буквы');
      return;
    }
    onSubmit(word);
  };

  return (
    <div style={{ backgroundColor: theme.modal.overlay }} className="fixed inset-0 flex items-center justify-center p-4">
      <motion.div 
        style={{ backgroundColor: theme.modal.background }}
        className="p-6 rounded-lg max-w-md w-full"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
      >
        <h2 className="text-xl font-bold mb-4">Введите своё слово</h2>
        <input
          ref={inputRef}
          type="text"
          value={customWord}
          onChange={(e) => setCustomWord(e.target.value)}
          style={{ backgroundColor: theme.modal.input.background }}
          className="w-full p-2 rounded text-white mb-4"
          maxLength={wordLength}
        />
        {error && <p style={{ color: theme.text.error }} className="mb-4">{error}</p>}
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            style={{ backgroundColor: theme.button.secondary.base }}
            className="px-4 py-2 rounded hover:bg-gray-600"
          >
            Отмена
          </button>
          <button
            onClick={handleSubmit}
            style={{ backgroundColor: theme.button.primary.base }}
            className="px-4 py-2 rounded hover:bg-pink-400"
          >
            Создать
          </button>
        </div>
      </motion.div>
    </div>
  );
};

function App() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showCustomWordModal, setShowCustomWordModal] = useState(false);
  const [wordLength, setWordLength] = useState<5 | 6 | 7>(5);
  const [dictionary, setDictionary] = useState<string[]>([]);
  const [targetWord, setTargetWord] = useState('');
  const [guesses, setGuesses] = useState<string[]>([]);
  const [currentGuess, setCurrentGuess] = useState('');
  const [gameStatus, setGameStatus] = useState<'playing' | 'won' | 'lost'>('playing');
  const [showError, setShowError] = useState(false);
  const maxGuesses = {
    5: 6,
    6: 8,
    7: 10,
  }[wordLength];
  const [keyboardState, setKeyboardState] = useState<Record<string, KeyboardKey['state']>>({});

  // Load dictionary and select random word
  useEffect(() => {
    const loadDictionary = async () => {
      const response = await fetch(`/nouns${wordLength}.txt`);
      const text = await response.text();
      const words = text.split('\n').filter(word => word.length === wordLength);
      setDictionary(words);
      
      // Check for custom word in URL
      const encodedWord = searchParams.get('w');
      if (encodedWord) {
        const decodedWord = decodeWord(encodedWord);
        setWordLength(decodedWord.length as 5 | 6 | 7);
        if (decodedWord && /^[а-яё]+$/.test(decodedWord)) {
          setTargetWord(decodedWord);
          return;
        }
      }
      setTargetWord(words[Math.floor(Math.random() * words.length)]);
    };
    loadDictionary();
  }, [wordLength, searchParams]);

  const handleGuess = () => {
    if (currentGuess.length !== wordLength) return;
    
    if (!dictionary.includes(currentGuess) && currentGuess !== targetWord) {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
      return;
    }

    const newGuesses = [...guesses, currentGuess];
    setGuesses(newGuesses);
    updateKeyboardState(currentGuess);
    setCurrentGuess('');

    if (currentGuess === targetWord) {
      setGameStatus('won');
    } else if (newGuesses.length >= maxGuesses) {
      setGameStatus('lost');
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (gameStatus !== 'playing' || showCustomWordModal) return;

      if (e.key === 'Enter') {
        handleGuess();
      } else if (e.key === 'Backspace') {
        setCurrentGuess(prev => prev.slice(0, -1));
      } else if (
        e.key.match(/^[а-яё]$/i) && 
        currentGuess.length < wordLength
      ) {
        setCurrentGuess(prev => prev + e.key.toLowerCase());
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentGuess, guesses, targetWord, dictionary, gameStatus, wordLength, showCustomWordModal]);

  // Update the click handler
  const handleKeyClick = (letter: string) => {
    if (gameStatus !== 'playing') return;
    
    if (letter === 'ENTER') {
      handleGuess();
    } else if (letter === 'BACK') {
      setCurrentGuess(prev => prev.slice(0, -1));
    } else if (currentGuess.length < wordLength) {
      setCurrentGuess(prev => prev + letter);
    }
  };

  // Check letters in guess
  const checkGuess = (guess: string): GuessResult[] => {
    const result: GuessResult[] = [];
    const targetLetters = Array.from(targetWord);

    // First pass: mark correct letters
    Array.from(guess).forEach((letter, i) => {
      if (letter === targetLetters[i]) {
        result[i] = { letter, state: 'correct' };
        targetLetters[i] = '*';
      }
    });

    // Second pass: mark wrong position and incorrect
    Array.from(guess).forEach((letter, i) => {
      if (result[i]) return;
      
      const targetIndex = targetLetters.indexOf(letter);
      if (targetIndex !== -1) {
        result[i] = { letter, state: 'wrong-position' };
        targetLetters[targetIndex] = '*';
      } else {
        result[i] = { letter, state: 'incorrect' };
      }
    });

    return result;
  };

  const updateKeyboardState = (guess: string) => {
    const results = checkGuess(guess);
    const newState = { ...keyboardState };
    
    results.forEach(({ letter, state }) => {
      // Only upgrade the state, never downgrade
      if (state === 'correct') {
        newState[letter] = 'correct';
      } else if (state === 'wrong-position' && newState[letter] !== 'correct') {
        newState[letter] = 'wrong-position';
      } else if (!newState[letter]) {
        newState[letter] = state;
      }
    });
    
    setKeyboardState(newState);
  };

  // Add this function to handle custom word submission
  const handleCustomWordSubmit = (word: string) => {
    setShowCustomWordModal(false);
    const encodedWord = encodeWord(word);
    const url = `${window.location.origin}${window.location.pathname}?w=${encodedWord}`;
    navigator.clipboard.writeText(url);
    alert('Ссылка скопирована в буфер обмена!');
    navigate(`?w=${encodedWord}`);
    setTargetWord(word);
    setGuesses([]);
    setCurrentGuess('');
    setGameStatus('playing');
    setKeyboardState({});
  };

  return (
    <div className="min-h-screen text-white p-8" style={{
      background: `linear-gradient(to bottom, ${theme.background.gradient.from}, ${theme.background.gradient.to})`
    }}>
      <header className="text-center mb-8">
        <motion.h1 
          className="text-4xl font-bold mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          Быки и коровы
        </motion.h1>
        <div className="flex justify-center gap-4 mb-8">
          {[5, 6, 7].map(length => (
            <motion.button
              key={length}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                backgroundColor: wordLength === length 
                  ? theme.button.primary.base
                  : theme.button.secondary.base,
                color: wordLength === length 
                  ? theme.text.primary 
                  : theme.text.secondary
              }}
              className="px-4 py-2 rounded-lg"
              onClick={() => {
                setWordLength(length as 5 | 6 | 7);
                setGuesses([]);
                setCurrentGuess('');
                setGameStatus('playing');
                navigate('/');
              }}
            >
              {length} букв
            </motion.button>
          ))}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{ backgroundColor: theme.button.secondary.base }}
            className="px-4 py-2 rounded-full hover:bg-indigo-600"
            onClick={() => setShowCustomWordModal(true)}
          >
            +
          </motion.button>
        </div>
      </header>

      {/* Add the modal */}
      {showCustomWordModal && (
        <CustomWordModal
          wordLength={wordLength}
          onSubmit={handleCustomWordSubmit}
          onClose={() => setShowCustomWordModal(false)}
        />
      )}

      <div className="max-w-md mx-auto">
        {guesses.map((guess, i) => (
          <motion.div 
            key={i}
            className="flex justify-center gap-2 mb-2"
          >
            {checkGuess(guess).map((result, j) => (
              <motion.div
                key={j}
                style={{
                  backgroundColor: 
                    result.state === 'correct' ? theme.letter.correct :
                    result.state === 'wrong-position' ? theme.letter.wrongPosition :
                    theme.letter.incorrect
                }}
                className="w-12 h-12 flex items-center justify-center rounded-lg text-2xl font-bold"
                initial={{ rotateX: 0 }}
                animate={{ rotateX: 360 }}
                transition={{ delay: j * 0.1 }}
              >
                {result.letter.toUpperCase()}
              </motion.div>
            ))}
          </motion.div>
        ))}

        {gameStatus === 'playing' && (
          <motion.div 
            className="flex justify-center gap-2 mb-2"
            initial={false}
            animate={{ scale: showError ? [1, 1.05, 1] : 1 }}
          >
            {Array.from(currentGuess.padEnd(wordLength)).map((letter, i) => (
              <div
                key={i}
                style={{
                  backgroundColor: theme.letter.empty,
                  borderColor: theme.letter.border
                }}
                className="w-12 h-12 flex items-center justify-center rounded-lg text-2xl font-bold border-2"
              >
                {letter !== ' ' ? letter.toUpperCase() : ''}
              </div>
            ))}
          </motion.div>
        )}

        {gameStatus === 'playing' && [...Array(maxGuesses - guesses.length - 1)].map((_, i) => (
          <motion.div 
            key={`empty-${i}`}
            className="flex justify-center gap-2 mb-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: (guesses.length + i) * 0.1 }}
          >
            {[...Array(wordLength)].map((_, j) => (
              <div
                key={j}
                style={{ backgroundColor: theme.letter.emptyBackground }}
                className="w-12 h-12 flex items-center justify-center rounded-lg text-2xl font-bold"
              />
            ))}
          </motion.div>
        ))}

        {showError && (
          <motion.p
            style={{ color: theme.text.error }}
            className="text-center mt-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            Слово не найдено в словаре
          </motion.p>
        )}

        {gameStatus !== 'playing' && (
          <motion.div
            className="text-center mt-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <p className="text-2xl mb-4">
              {gameStatus === 'won' ? '🎉 Поздравляем!' : '😔 Попробуйте еще раз'}
            </p>
            <p className="text-xl mb-4">Слово было: {targetWord.toUpperCase()}</p>
            <motion.button
              className="bg-pink-500 px-6 py-3 rounded-lg font-bold"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                setGuesses([]);
                setCurrentGuess('');
                setGameStatus('playing');
                setKeyboardState({});
                setTargetWord(dictionary[Math.floor(Math.random() * dictionary.length)]);
              }}
            >
              Играть снова
            </motion.button>
          </motion.div>
        )}

        <div className="mt-8">
          {KEYBOARD_LAYOUT.map((row, i) => (
            <motion.div 
              key={i}
              className="flex justify-center gap-1 mb-1"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 + i * 0.1 }}
            >
              {row.map((letter) => (
                <motion.div
                  key={letter}
                  style={{
                    backgroundColor: 
                      letter === 'ENTER' || letter === 'BACK'
                        ? theme.button.keyboard.special.base
                        : keyboardState[letter] === 'correct'
                        ? theme.letter.correct
                        : keyboardState[letter] === 'wrong-position'
                        ? theme.letter.wrongPosition
                        : keyboardState[letter] === 'incorrect'
                        ? theme.letter.incorrect
                        : theme.button.keyboard.default.base,
                    cursor: 'pointer'
                  }}
                  className={`rounded-md text-sm font-bold transition-colors flex items-center justify-center focus:outline-none ${
                    letter === 'ENTER' || letter === 'BACK'
                      ? 'w-16 h-10'
                      : 'w-8 h-10'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleKeyClick(letter)}
                >
                  {letter === 'BACK' ? '←' : letter.toUpperCase()}
                </motion.div>
              ))}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
