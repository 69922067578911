export const theme = {
    // Background colors
    background: {
      primary: '#363474', // slightly desaturated indigo-900
      gradient: {
        from: '#5c2a80', // slightly desaturated purple-900
        to: '#363474', // slightly desaturated indigo-900
      }
    },
    
    // Button colors
    button: {
      primary: {
        base: '#e15c91', // slightly desaturated pink-500
        hover: '#eb82aa', // slightly desaturated pink-400
      },
      secondary: {
        base: '#374151', // keeping original gray-700
        hover: '#4b5563', // keeping original gray-600
      },
      keyboard: {
        default: {
          base: '#6b7280', // keeping original gray-500
          hover: '#9ca3af', // keeping original gray-400
        },
        special: {
          base: '#4b5563', // keeping original gray-600
          hover: '#6b7280', // keeping original gray-500
        }
      }
    },

    // Letter states
    letter: {
      correct: '#2fb062', // slightly desaturated green-500
      wrongPosition: '#e0ac15', // slightly desaturated yellow-500
      incorrect: '#374151', // keeping original gray-700
      empty: '#374151', // keeping original gray-700
      border: '#6b7280', // keeping original gray-500
      emptyBackground: 'rgba(107, 114, 128, 0.3)', // keeping original
    },

    // Text colors
    text: {
      primary: '#ffffff',
      secondary: '#d1d5db',
      error: '#f87171',
    },

    // Modal colors
    modal: {
      overlay: 'rgba(0, 0, 0, 0.5)',
      background: '#1f2937',
      input: {
        background: '#374151',
      }
    }
} as const;

export type Theme = typeof theme; 